/** According to https://www.sessions.edu/color-calculator/
* the complementary colours (tetradic) for #151045 are:
  HEX: #404510
	HEX: #104515
	HEX: #451040
*/


:root {
	--main-purple: #151045;
	--main-olive: #404510;
	--main-green: #104515;
	--main-magenta: #451040;
	--main-yellow: #c3dc08;

	--silver-rail: #888488;
	--shadow: rgba(34,34,34,.5);
}

html, body {
    padding: 0;
    margin: 0;
    font-family: 'Magra', sans-serif;
    background: #eeeeee url('bg.jpg');
		height: 100vh;
    line-height: 14px;
}


a, button { 
	text-decoration: none;
	color: #eee;
}

header {
	text-transform: uppercase;
	padding: 8px 16px;
	background-color: var(--main-purple);
	opacity: 0.85;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 2px;
	border-bottom: 2px solid var(--silver-rail);
	padding-left: 1em;
	padding-right: 1em;
	display: flex;
	justify-content: space-between;
	box-shadow: 0 10px 10px -4px var(--shadow);

}

.glowup {
	text-shadow: 1px 1px 2px #222;
	color: #eee;
	transition: all .2s ease-in-out;
}

.glowup:hover {
	text-shadow: 2px 2px 2px #000;
  color: #ffffff;
	transform: scale(1.1);
}

.Links ul {
	animation: growDown 300ms ease-in-out forwards;
	transform-origin: top right;
}

@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
}

.NameHolder {
	flex: 0 0 80%;
}

.Links {
	z-index: 99;
	position: absolute;
	top: 4px;
	right: 4px;
	margin: 0;
	padding: 2px;
	font-size: 16px;
	background: var(--main-purple);
	text-align: right;
	border: 2px solid var(--silver-rail); 
	border-radius: 8px;
	opacity: 1;
	box-shadow: 0 10px 10px -4px var(--shadow);

}

.Links button {
	font-size: 16px;
	font-family: 'Magra', sans-serif;
	letter-spacing: 2px;
	outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.Links a {
	border: 1px solid transparent;
	border-radius: 2px;
	padding: 2px;
	display: inline-block;
}

.Links ul {
	list-style-type: none;
}

/** CSS animation Flip stuff from https://stackoverflow.com/a/76966449/649048 */

.flip-container {
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Add vendor prefixes for better browser compatibility */
.flip-container:hover .flipper {
  transform: rotateX(180deg);
}

.back {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

p {
	line-height: 1.5em;
}

p a {
	color: var(--main-yellow);
	text-shadow: 1px 1px 0px #222;
}

p a:hover, h3 a:hover {
	color: var(--main-green);
	text-shadow: 1px 1px 0px #333;
}